@import "../../shared/styles/variables.scss";

.okta-logout {
  display: flex;
  justify-content: center;
  width: 100%;

  .logout-button {
    align-items: center;
    background-color: $primary-blue;
    box-shadow: $navbar-box-shadow;
    color: $white;
    display: flex;
    font-weight: 700;
    height: 56px;
    justify-content: space-evenly;
    width: 85%;
  }
}
