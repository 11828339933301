@import "../../../../shared/styles/variables.scss";

.row-container {
    background-color: white;
    padding: 16px 8px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-transform: capitalize;
    display: flex;
}

.row-container a {
    color: $primary-blue-400;
}

.row-alternate-bg {
    background-color: $neutral-200;
}