@import "../../../shared/styles/variables.scss";

.patient-blood-sample-container {
  width: 100%;

  .add-blood-sample {
    padding-bottom: 32px;

    .blood-sample-input-container {
      padding: 1rem;
    }

    .add-button-container {
      padding-left: 8px;

      .add-button {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        border-radius: 4px;
      }

      .add-button-enabled {
        background-color: $primary-blue-400;
        color: $neutral-50;
      }
    }
  }

  .table-container {
    background-color: white;
    border-collapse: collapse;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;

    .body-container {
      display: block;
      width: 100%;
      max-height: 55vh;
      overflow-y: auto;

      .sample-history-row-container {
        .cancel-button-container {
          padding-right: 16px;
        }

        .square-icon-button {
          border-radius: 8px;
        }

        .cancel-button {
          border: 1px solid $neutral-400;
          background-color: $neutral-50;
          margin-right: 16px
        }

        .save-button {
          background-color: $primary-blue-400;
        }

        .edit-button-enabled {
          border: 1px solid $neutral-400;
          background-color: $neutral-50;
        }
      }
    }
  }
}
