.patient-list-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 16px;
}

.container-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0px 8px 0px;
}

.table-container {
  background-color: white;
  border-collapse: collapse;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.search-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 0px 24px 0px;
}

.search-input {
  margin-right: 30px;
  flex-grow: 1;
}

.body-container {
  display: block;
  width: 100%;
  margin-top: 2px;
  overflow-y: auto;
  max-height: 55vh;
}

.list-container {
  display: flex;
  flex-direction: column;
}
