@import "./shared/styles/variables.scss";

body {
  margin: 0;
  padding: 0;
}

.App {
  display: block;
  font-family: "Montserrat", "Roboto", sans-serif;

  .app-content {
    display: flex;
    background-color: $light-grey;
    height: 100vh;
    width: 100vw;

    .page-content {
      display: flex;
      flex-direction: column;
      width: 100vw;
      overflow-y: scroll;

      .main-content {
        align-items: flex-start;
        display: flex;
        height: 100%;
        width: 100%;
      }
    }
  }
}
