@import '../../../../shared/styles/variables.scss';

.infusion-note-delete-verify-container{
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    top: 0px;
    background-color: rgba(255, 255, 255, 0.9);
    align-items: center;
    justify-content: center;

    display: flex;
    flex-direction: column;
    border-radius: 8px;
} 

.infusion-note-delete-verify-title {
    font-weight: bold;
    margin-bottom: 16px;
    color: $primary-blue
}

.infusion-note-delete-verify-controls {
    display: flex;
    justify-content: space-between;
    width: 250px;
    .ok-button{
        background-color: $primary-blue;
        color: white;
        font-size: 13px;
        &:hover {
            background-color: $primary-blue-400;
        }
    }
    .cancel-button{
        background-color: $neutral-300;
        color: $neutral-600;
        font-size: 13px;
        &:hover {
            background-color: $neutral-400;
            color: $neutral-100;
        }
    }
}

