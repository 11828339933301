@import "../../shared/styles/variables.scss";

.patient-controls {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 30px;
}

.go-back-button {
    width: 96px;
    font-size: 16px;
    background-color: $neutral-50;
    color: $primary-blue-400;
    border: 1px solid $neutral-400;
    margin-right: 16px;
}